/************ Font ************/
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Madimi+One&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

/************ Colors ************/

:root {
  --first-color: #e6e6e6;
  --second-color: #c44285;
  --third-color: #010101bd;
  --fourth-color: #176589;
  --fifth-color: #999;
  --sixth-color: #010101;
  --seventh-color: #d9d9d91a;
  --eighth-color: #0c3b50;
  --nineth-color: #092c3c87;
  --tenth-color: #c4428599;

}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}
body {
  overflow-x: hidden;
  position: relative;
  color: var(--first-color) !important;
  font-family: "Roboto", sans-serif;
}
p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
button {
  border: 0;
  background-color: transparent;
}
span {
  font-style: italic;
}
.invert{
  filter: invert(100%);
}

/************ Header Css ************/
.header {
  background: var(--sixth-color);
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 1;
  top: 0;
}
.div-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.elojocuantico {
  font-family: Anton;
  color: var(--second-color);
  text-align: center;
}
.magazine {
  font-size: small;
  text-align: center;
}
.name {
  font-size: medium;
  font-style: italic;
}
.logo-container {
  position: relative;
  z-index: 9999;
  width: 120px;
  height: 92px;
  overflow: hidden;
}
.logo,
.logo2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 110px;
  object-fit: contain;
  animation-duration: 30s;
  animation-iteration-count: infinite;
}
@media(max-width: 460px) {
  .logo,
  .logo2 {
    animation: none;
    width: 90px;
    padding-top: 10px;
  }
  .logo-container {
    width: 100px !important;
  }
  .elojocuantico{
    font-size: 18px !important;
  }
}
.logo {
  animation-name: unfade;
}
.logo2 {
  animation-name: fade;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  93% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes unfade {
  0% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.tabs {
  margin: 0 5px;
  text-align: center;
  color: var(--first-color);
}
.nav-link.active {
  color: var(--first-color) !important;
}
.tabs:hover {
  color: var(--second-color);
  background: transparent !important;
}
span.header-text {
  display: flex;
  align-items: center;
}
.navbar .nav-link.tabs,
.navbar .nav-link.tabs:visited {
  margin: 0 5px;
  text-align: center;
  color: var(--first-color);
}

.navbar .nav-link.tabs:hover,
.navbar .nav-link.tabs:visited:hover {
  color: var(--second-color);
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: var(--seventh-color);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid var(--seventh-color);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: var(--first-color);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.dropdown-menu{
  background: var(--eighth-color);
}
.dropdown-item{
  color: var(--first-color);
}
.dropdown-item:hover{
  background: var(--fourth-color);
  color: var(--first-color);
}

/************ Menu Css ************/
.menu-div {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(120deg, #e8e8d15a 0%, #eae3fdbb 100%),
    url("../src/assets/img/fondo3.jpeg");
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
  position: fixed;
  z-index: -1; 
}

/************phrases***********************/

.phrase-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 120px 5px 5px 5px;
  z-index: 2;
}
#phrase-button {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.text-button {
  color: var(--sixth-color);
  text-shadow: 0px 0px 5px #fff;
  font-weight: 800;
}
#phrase-div {
  position: fixed;
  top: 10%;
  left: 10%;
  z-index: 2;
  width: 80%;
  height: 85vh;
  background: url("./assets/img/pergam3.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: #111;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 768px) {
  #phrase-div {
    background: url("./assets/img/pergam1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 5%;
    left: 0%;
    width: 100%;
    height: 90vh;
  }
  .textandbutton-div {
    width: 65%;
  }
}
.textandbutton-div {
  width: 75%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#phrase-text {
  font-size: 20px;
  width: 70%;
  margin-bottom: 30px;
}
#goon-button {
  padding: 7px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #111;
}
#goon-button:hover {
  border-radius: 10px;
  box-shadow: 0px 0px 10px #fff;
}

/************ Home Css ************/

.card-theme {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 20px;
}
.card-theme:hover .theme {
  box-shadow: 4px 4px 10px var(--sixth-color);
}
.card-theme:hover .tit {
  color: var(--fourth-color);
  text-shadow: 1px 1px 5px #fff;
}
.tit {
  font-size: 20px;
  padding: 10px;
  margin: 10px;
  height: 100px;
  color: #000b;
  text-shadow: 0 0 10px var(--first-color);
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}
.subtit {
  padding-top: 20px;
  color: var(--sixth-color);
}
.section-tit{
  color: var(--sixth-color);
  font-weight: 600;
}
.themes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
}
.theme {
  width: 250px;
  height: 250px;
  margin: auto;
  border-radius: 10px;
  background-size: cover;
  box-shadow: 0 0 10px var(--sixth-color);
}
/*

.tema2 {
  background-image: url("../src/assets/img/");
}
.game-wordle{
  background-image: url("../src/assets/img/wordle2.jpg");
}*/
.tema1 {
  background-image: url("../src/assets/img/teatro2.jpg");
}
.tema2 {
  background-image: url("../src/assets/img/time2.jpg");
}

.tema3 {
  background-image: url("../src/assets/img/body1.jpg");
}

.tema4{
  background-image: url("../src/assets/img/magicnora2.jpg");
}
  
.tema5 {
  background-image: url("../src/assets/img/crossword2.jpg");
}
  
.titles {
  height: 120px;
  margin: 10px 0;
  padding-top: 40px;
  padding-left: 30px;
  font-size: 22px;
  text-align: left;
  text-transform: uppercase;
  text-shadow: 3px 3px 10px var(--first-color);
}
@media (max-width: 767px) {
  .titles,
  .indice {
    text-align: center;
    padding-top: 0px;
    height: 80px;
  }
}
/************ Articles Css ************/
.section-app {
  width: 80%;
  margin: auto;
}
.article-div {
  width: 90%;
  margin: 92px auto;
  margin-bottom: 30px;
  background: var(--third-color);
  border-radius: 10px;
  padding: 30px 30px;
  letter-spacing: 1px;
}
.article-p {
  font-size: 22px;
  line-height: 1.5;
  padding: 20px 0;
  text-indent: 3em;
}
.article-pp {
  font-size: 22px;
  line-height: 1.5;
  padding: 20px 0;
  text-indent: 3em;
}
.article-img {
  width: 250px;
  float: left;
  border-radius: 20px;
  padding: 10px;
}
.article-img2{
  width: 350px;
  float: left;
  border-radius: 20px;
  padding: 10px;
}
.article-img3{
  width: 450px;
  float: left;
  border-radius: 20px;
  padding: 10px;
}
.article-img4{
  width: 200px;
  float: right;
  border-radius: 20px;
  padding: 10px;
}
.article-img5{
  width: 550px;
  border-radius: 20px;
  padding: 10px;
}
.text-right{
  text-align: right;
}
.italic {
  font-style: italic;
}
.download{
  color: var(--first-color);
  border-radius: 10px;
  padding: 10px;
  background: var(--nineth-color);
}
.download:hover{
  background: var(--eighth-color);
}
.download-img{
  width: 30px;
  margin-left: 10px;
}
@media(max-width: 767px) {
  .article-div{
    margin: 92px auto;
    margin-bottom: 30px;
  }
  .article-p{
    font-size: 18px;
  }
  .article-pp {
    font-size: 18px;
    text-indent: 0px;
    text-align: left;
  }
  .article-img {
    width: 150px;
  }
  .article-img2{
    width: 250px;
  }
  .article-img3{
    width: 250px;
  }
  .article-img4{
    width: 100px;
    float: right;
  }
  .article-img5{
    width: 400px;
  }
}
.exp-int{
  background: var(--third-color);
}
.exp-title{
  width: 100%;
  text-align: center;
  padding: 30px;
}
.button-arrow{
  padding: 8px;
  border-radius: 6px;
  border: 2px solid var(--nineth-color);
  margin-bottom: 20px;
}
.back-arrow{
  width: 22px;
  filter: invert(100%);
}
.button-arrow:hover{
  border: 2px solid var(--fourth-color);
}
@media(max-width:768px){
  .back-arrow{
    width: 15px;
  }
}

.audio-title{
  text-align: center;
padding: 20px;
}
.audio-btn{
  background: #fff;
  border-radius: 15px;
  font-size: 16px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.audio-img{
  width: 50px;
  margin: 0 10px;
}
@media(max-width:568px){
  .audio-img{
    display: none;
  }
  .audiocontrols{
    width: 250px;
  }
  .article-img5{
    width: 300px;
  }
}

/************ About us Css ************/
.aboutus-img {
  width: 250px;
  float: left;
  border-radius: 10px;
  scale: 1.2;
}
@media(max-width:768px){
  .aboutus-img{
    width: 200px;
  }
}
.aboutus-title {
  text-align: center;
}
.item-title{
  color: rgb(195, 182, 255);
  font-size:larger;
  font-weight: 600;
  text-shadow: 0px 0px 5px var(--sixth-color);
}

/**************workshops*************/
.workshop-div{
  margin-top: 92px;
  padding: 5%;
  text-align: center;
}
.workshops-container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.workshop-item {
  flex: 0 0 calc(33.33% - 20px);
  margin: 10px;
  background: var(--third-color);
  box-shadow: 0px 0px 10px #faf7f7;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  color: var(--first-color);
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.workshop-item img {
  width: 100px;
  margin-bottom: 15px;
}
.workshop-item p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
}
.whats{
  width: 30px !important;
  margin: 0px !important;
}
@media (max-width: 867px) {
  .workshop-item {
    flex: 0 0 calc(50% - 20px);
    font-size: 18px;
    font-weight: 400;
  }
}
@media (max-width: 567px) {
  .workshop-div{
    margin-top: 92px;
  }
  .workshop-item {
    flex: 0 0 calc(100%);
    font-size: 18px;
    font-weight: 400;
    margin: 10px;
  }
  .workshop-item p {
    font-size: 16px;
  }
}

/************ Contact Css ************/
.title-form {
  color: var(--first-color);
  border-radius: 5px;
}
.contact-icon {
  width: 80px;
  border-radius: 50%;
  margin-left: 10px;
}
.contact-div {
  margin: 5%;
  margin-top: 92px;
  padding: 5%;
  width: 70%;
  background-color: var(--fourth-color);
  border-radius: 5px;
}
#contact {
  display: flex;
  justify-content: center;
}
.btn-send {
  color: var(--first-color);
  box-shadow: 0px 0px 10px #111;
}
.btn-send:hover {
  letter-spacing: 1px;
  font-weight: 600;
  box-shadow: 0px 0px 10px #fff;
}
@media (max-width: 567px) {
  .contact-div {
    width: 95%;
    margin-top: 92px;
  }
}

/************ Footer Css ************/
.footer-div {
  padding: 40px 0px 20px 0px;
  background: var(--sixth-color);
  display: flex;
  justify-content: center;
  align-items: center;
}


/************ MemoryGame Css ************/
.game-title {
  padding-top: 40px;
}
.new-game {
  background: none;
  border: 1px solid #fff;
  padding: 6px 12px;
  border-radius: 4px;
  color: #fff;
  font-size: 1em;
  background: var(--sixth-color);
}
.new-game:hover {
  background: var(--second-color);
  color: #fff;
}
.memory-game {
  text-align: center;
  margin: 3%;
  margin-top: 92px;
  padding-bottom: 40px;
  color: var(--sixth-color);
}
.card-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  justify-items: center;
  max-width: 700px;
}

@media (max-width: 480px) {
  .card-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 3px;
  }
  .memory-game{
    margin-top: 92px;
  }
}
.card {
  position: relative;
}
.card img {
  width: 100%;
  display: block;
  border: 2px solid white;
  border-radius: 6px;
}
.card .front {
  transform: rotateY(90deg);
  transition: all ease-in 0.2s;
  position: absolute;
}
.flipped .front {
  transform: rotateY(0deg);
  transition-delay: 0.2s;
}
.card .back {
  transition: all ease-in 0.2s;
  transition-delay: 0.2s;
}
.flipped .back {
  transform: rotateY(90deg);
  transition-delay: 0s;
}
/************ Old Editions Css ************/
 .old-editions-div {
  margin: 5%;
  margin-top: 92px;
  padding: 5%;
  text-align: center;
  min-height: 80vh;
}

.old-editions {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.old-month-edition {
  margin: 20px;
  padding-top: 20px;
  min-width: 300px;
  border-radius: 20px;
  background: var(--nineth-color);
}
.games-edition{
  margin: 20px;
  padding-top: 20px;
  min-width: 300px;
  border-radius: 20px;
  background: var(--tenth-color);
}

.old-articles {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.old-articles a {
  color: var(--first-color);
  font-size: large;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.old-articles a:hover {
  background: var(--nineth-color);
}

.month-title {
  color: var(--first-color);
  font-weight: 600;
}
.small-img{
  width: 80px !important;
  border-radius: 10px !important;
  margin-right: 10px !important;
  border: 2px solid var(--eighth-color);

}
.elem-article-div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 768px) {
  .old-month-edition {
    min-width: 100%; 
    margin: 10px 0; 
  }
  .old-editions{
    flex-direction: column;
  }
  .old-editions-div{
    margin-top: 92px;
  }
  .small-img{
    width: 60px !important;
  }
}

@media (max-width: 480px) {
  .old-articles a {
    font-size: medium;
  }
}

/************ Games Css ************/

.wordle-div{
  margin: 5%;
  margin-top: 92px;
}

.jump-div{
  margin: 5%;
  margin-top: 92px;
  padding: 5px;
  text-align: center;
  color:var(--sixth-color);
}

.crossword-div{
  margin: 5%;
  margin-top: 92px;
  padding: 5px;
  text-align: center;
  color:var(--second-color);
}